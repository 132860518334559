export default function search() {

    $ = jQuery;
    const $search = $('.js_header_search'),
          $searchInput = $('.js_header_search_input'),
          $searchResults = $('.js_header_search').find('.js_header_search_results'),
          $searchSubmit = $('.js_header_search_submit'),
          searchActiveClass = 'is-active',
          doneTypingInterval = 100;
    let typingTimer;


    $searchSubmit.on('click', function () {
        const $thisSearch = $(this).closest('.js_header_search');
        if ($thisSearch.hasClass(searchActiveClass)) {
            $thisSearch.removeClass(searchActiveClass);
            $searchResults.removeClass('is-visible');
            $searchInput.val('');
        } else {
            $thisSearch.addClass(searchActiveClass);
            $searchInput.focus();
        }
    });

    $searchInput.on('keyup', function () {
        clearTimeout(typingTimer);
        const $input = $(this);
        typingTimer = setTimeout(() => {
            handleSearchFetch($input.val());
        }, doneTypingInterval);
    });

    $(document).on('click', function (event) {
        if (!$search.is(event.target) && $search.has(event.target).length === 0) {
            $search.removeClass(searchActiveClass);
            $searchInput.val('');
            $searchResults.removeClass('is-visible').empty();
        }
    });

    $search.click(function (e) {
        e.stopPropagation();
    });

    function handleSearchFetch(queryVal) {
        if (!queryVal) {
            $searchResults.empty().removeClass('is-visible');
            return;
        }

        fetch('/wp-json/mda/v1/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query: queryVal })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            $searchResults.empty().addClass('is-visible');
            if (data && data.content) {
                $searchResults.html(data.content);
            }
        })
        .catch(error => {
            $searchResults.empty().removeClass('is-visible');
        });
    }
}
