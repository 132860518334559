import Aos from 'aos';
import burgerMenu from './modules/burger-mobile';
import filters from './modules/filters';
import footerMenu from './modules/footer-menu';
import lang from './modules/language-switcher';
import lastAccordion from './modules/last-accordion';
import megaMenu from './modules/mega-menu';
import sliderProduct from './modules/product-swiper';
import rangeSlider from './modules/range-slider';
import search from './modules/search';
import SmoothScroll from './modules/smooth-scroll';
import pageHeaderSticky from './modules/sticky-header';
import carousels from './modules/swiper';
import setGalleries from './modules/lightbox';

var documentReady = function () {
    search();
    lastAccordion();
    lang();
    filters();
    footerMenu();
    burgerMenu();
    pageHeaderSticky();
    megaMenu();
    carousels();
    rangeSlider();
    sliderProduct();
    require('./modules/accordion');
    SmoothScroll();
    setGalleries();

    Aos.init({
        disable: 'mobile',
        anchorPlacement: 'top-bottom',
        once: true,
    });
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
