export default function burgerMenu() {
    $ = jQuery;

    let $burger = $('.js-burger'),
        $nav = $('#js-mobile-fixed-nav'),
        $header = $('#js-header'),
        $header_logo = $('.header__container-image'),
        $body = $('body');

    let $menuLink = $('.js-mobile-fixed-nav-menu-item-parent > a'),
        $navTitle = $('.js-main-menu-mobile-title'),
        $navMenu = $('.js-main-menu-mobile-menu'),
        $navSubmenu = $('.js-main-menu-mobile-submenu');

    $burger.on('click', function (e) {
        e.preventDefault();

        $burger.toggleClass('-open');

        if ($nav.hasClass('is-open')) {
            $nav.removeClass('is-open');
            $('body').removeClass('no-scroll');
            $header.removeClass('is-open');
            $header_logo.removeClass('is-hide');
            $body.removeClass('-noscroll');
        } else {
            $nav.addClass('is-open');
            $('body').addClass('no-scroll');
            $header.addClass('is-open');
            $navTitle.text('Menu');
            $navMenu.removeClass('is-hide');
            $navSubmenu.removeClass('is-open');
            $header_logo.addClass('is-hide');
            $body.addClass('-noscroll');
        }
    });

    $menuLink.each(function (index) {
        $(this).on('click', function (e) {
            let $this = $(this),
                activeName = $this.text();

            $('.js-main-menu-mobile-submenu').removeClass('is-open');

            $navTitle.html(
                '<i class="icon icon-ARROW_LEFT header-menu-mobile__title-icon"></i>' +
                    activeName
            );

            $navMenu.addClass('is-hide');

            $this
                .parent()
                .find('.js-main-menu-mobile-submenu')
                .first()
                .addClass('is-open');

            const parenting = $this
                .parent()
                .parent()
                .hasClass('js-main-menu-mobile-submenu');

            if (parenting) {
                $this.parent().parent().addClass('is-open');
            }
        });
    });

    $navTitle.on('click', function (e) {
        e.preventDefault();

        $(this).text('Menu');
        $navMenu.removeClass('is-hide');
        $navSubmenu.removeClass('is-open');
    });
}
